
.filter{
    &[data-background-color="blue"] {
        @include set-background-color($blue);
      }
    
      &[data-background-color="white"] {
        @include set-background-color($white);
      }
    
      &[data-background-color="black"] {
        @include set-background-color($black);
      }
    
      &[data-background-color="primary"] {
        @include set-background-color(map-get($primary-color, darken-1));
      }
    .count-label{
        font-size: 7em;
        opacity: .2;
        text-align: right;
        margin-bottom: 30px;
    }

    @media screen and (min-height: 992px) {
      .count-label{
        position: absolute;
        bottom: 50px;
        right: 10px;
        margin-bottom: 0px;
    }
    }


    .ct-layout{
        position: relative;
        .bt-reset{
            position: absolute;
            right: 0;
            top: 10px; 
        }
    }
    .noui-section{
        
        .layout-switch{
            padding-top: .5em;
        }
        .noUi-horizontal{
        margin-bottom: 0;
        font-size: .8em;
        }
    }
    .grid-x3{
      display: grid;
    grid: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 10px;
    }
}