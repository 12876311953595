// Width and height

.w-100 { width: 100% !important; }
.h-100 { height: 100% !important; }

// Margin and Padding

.mx-auto {
  margin-right: auto !important;
  margin-left:  auto !important;
}

a{
  cursor: pointer;
}

// Icons
.fonticon-wrap {
    width: 2%;
    float: left;
    margin: 0.25rem;
    i.fa{
      font-size: 1.35rem !important;
    }
}

.round{
  border-radius: 1.5rem;
  .input-group-text {
      border-top-right-radius: 1.5em;
      border-bottom-right-radius: 1.5em
      
    }
  
}

.img-selected{
    border: 5px solid $primary;
    position:relative;
    .triangle-top-right {
      position: absolute;
      top:0;
      right:0;
      span{
        position: relative;
        color: #fff;
      }
  }
  .triangle-top-right::before {
    content:" ";
    position: absolute;
    top:0;
    right:0;
    border-style: solid;
    border-width: 0 36px 36px 0;
    border-color: transparent $primary transparent transparent;
  }
}
.square{
  border-radius: 0;
}
img{
  &.img-border{
    border: 5px solid $white;
  }
}

ul{
  &.no-list-style{
    list-style: none;
  }
}

// Shadow classes
.shadow-z-1{
  @include shadow-z-1();
}
.shadow-z-1-hover{
  @include shadow-z-1-hover();
}
.shadow-z-2{
  @include shadow-z-2();
}
.shadow-z-3{
  @include shadow-z-3();
}
.shadow-z-4{
  @include shadow-z-4();
}
.shadow-z-5{
  @include shadow-z-5();
}
.big-shadow{
  @include big-shadow();
}
.shadow-big{
  @include shadow-big();
}
.shadow-big-navbar{
  @include shadow-big-navbar();
}
.sidebar-shadow{
  @include sidebar-shadow();
}
.card-shadow{
  @include card-shadow();
}

// Cursors

.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.cursor-default {
  cursor: default;
}
.cursor-progress {
  cursor: progress;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-grab {
  cursor: grab;
}