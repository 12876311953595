.dashboard {

    .inline-switcher {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
        margin-right: 20px;

        li {
            margin: 0 5px;
            opacity: .3;

            .symbol {
                padding: 1px 8px;
                margin-right: 5px;
                border-radius: 100%;
            }

            &.active {
                opacity: 1;
            }

            button {
                border: none;
                background: none;
                color: #fff;
                padding: 5px 10px;

            }
        }
    }


    .icon.icon-symbol-female {
        color: rgba(132, 5, 245, 1);
    }

    .icon.icon-symbol-male {
        color: rgba(40, 146, 255, 1);
    }

    .button-list {
        list-style: none;
        padding: 0;
        display: flex;
        margin: 0;

        button {
            font-size: .9em;
            font-weight: 600;
            padding: 0 10px;
            margin-right: 10px;
        }

        .btn-empty {
            color: rgba(255, 255, 255, .6);
        }
    }

    .card {
        margin: 10px 0;

        .sub-gender {
            display: flex;
            align-items: center;
            height: 80px;

            .info-chart {
                display: grid;
                margin: 0 20px 0 10px;
                color: #fff;

                span {
                    font-size: .9em;

                    &.large {
                        font-size: 2em;
                        line-height: 1;
                    }
                }
            }

            .icon {
                font-size: 2em;
            }
        }

        .sub-chart {
            padding: 20px;

            .card {
                background-color: rgba(0, 0, 0, .3) !important;
                margin: 0;
                min-height: 270px;

                .card-body {
                    padding-top: 0;
                }
            }
        }
    }

    .chartjs-container {
        margin: 0;
        min-height: calc(50vh - 250px);
        position: relative;
    }

    .chartjs-subcontainer {
        margin: 0;
        min-height: 100%;
        position: relative;
        width: 100%;
    }

    .chartjs-subcontainer-200 {
        margin: 0;
        min-height: 200px;
        position: relative;
        width: 100%;
    }

    &.dashboar-nav {
        .chartjs-container {
            min-height: calc(50vh - 240px);
        }
    }

    .card-header {
        padding-top: 10px;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;

        .dropdown-menu {
            .dropdown-item {
                width: 100%;
            }
        }

        .btn.dropdown-toggle {
            margin-left: auto;
            padding: 0;
            font-size: 1.2em;
            color: rgba(255, 255, 255, .6);

            &.dropdown-toggle::after {
                content: inherit;
            }

        }
    }

    .card-body.kpi {
        padding-top: 10px;
        font-size: 2.4em;
        display: flex;
        align-items: baseline;
        position: relative;
        color: #fff;
        min-height: 80px;

        .value-loading {
            position: relative;
            padding: 30px;

        }

        .inline-values {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            width: 100%;

            &.x2 {
                grid-template-columns: 1fr 1fr;
                font-size: 1.5em;
            }

            .item-value {
                text-align: center;
                display: grid;
                border-right: solid 1px rgba(255, 255, 255, .1);

                small {
                    font-size: .6em;
                }

                &:last-child {
                    border-right: none;
                }

                .icon {
                    font-size: .7em;
                    margin-top: 10px;


                }

                .lable-value {
                    font-size: .5em;

                    &.large {
                        font-size: 1em;
                    }
                }

                .title {
                    font-size: 11px;
                    opacity: .6;
                }

            }
        }

        .chart {
            min-height: 40px;
            margin: 0 40px;
        }

        .badge-progress {
            font-size: .3em;
            color: #fff;
            margin-left: 10px;
        }

        .img {
            display: flex;
            position: absolute;
            right: 30px;
            height: 100%;
            width: 15%;
            min-width: 30px;
            top: 10px;
            align-items: flex-start;
            justify-content: flex-end;
            font-size: 1.3em;

            img {
                width: 100%;
                max-width: 50px;
            }
        }

    }

    .card-body.card-body-kpi {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0;

        &.full {
            grid-template-columns: 1fr;
            padding: 0 25px;
        }

    }

    .card-kpi {
        height: 130px;
    }

    .pie-box {
        height: 90px;
        position: relative;


    }

    .leggend {
        ul {
            list-style: none;
            padding: 0;
            padding-left: 20px;
            margin-top: 20px;
            font-size: .8em;

            li {
                display: flex;
                align-items: center;

                .list-value {
                    font-weight: bold;
                }

                .symbol-list {
                    border-radius: 100%;
                    width: 8px;
                    height: 8px;
                    margin-right: 5px;
                }
            }
        }
    }
}