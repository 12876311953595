// basic badge
.badge {
	letter-spacing: 0.3px;
	background-color: $primary;
	color: $white;

	&.small {
		font-size: 0.62rem;
	}

	&.badge-light {
		color: $body-color;
	}

	// round badge
	&.badge-round {
		padding: 6px 8px;
	}
	&.badge-white{
		color: $black;
		background-color: $white;
	}
	&.badge-menu-children {
		opacity: 1;
		display: block;
		position: absolute;
		border-radius: 100%;
		transform: scale(.8);
		right: 0;
		top: -5px;
	}
}